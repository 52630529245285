/*!

=========================================================
* Argon Design System React - v1.1.0 based on Argon Design System - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Custom components

@import "custom/dropdown.scss";
@import "custom/mixins.scss";
@import "custom/navbar.scss";
@import "custom/popover.scss";
@import "custom/separator.scss";


// Vendor
@import "custom/vendor/react-datetime.scss";

// other custom styles
main {
  overflow: visible;
}
@media print {
  .print-box {
    margin: 1in;
  }

  .print-permit-box {
    width: 1000px !important;
    padding-top: 40px;

    .vessel-name-box {
      top: 402.864px !important;

      >div {
        >span {
          font-size: 24px;
          color: black;
        }
      }
    }

    .title-box {
      top: 482.864px !important;

      >div {
        >span {
          font-size: 24px;
          color: black;
        }
      }
    }

    .number-box {
      top: 620px !important;

      >div {
        >span {
          font-size: 28px !important;
          color: black;
        }
      }
    }

    .date-box {
      top: 690px !important;

      >div {
        >span {
          font-size: 36px !important;
          color: black;
        }
      }
    }
    
    .signature-image-box {
      position: absolute;
      top: 790px !important;
      left: 520px !important;
      text-align: center;

      >img {
        max-height: 80px !important;
        width: auto;
      }
    }
  }

  .print-inspection-box {
    position: relative;
    width: 1100px !important;
    margin: auto;
    &::before {
      content: ' ';
      display: block;
      width: 100%;
      // height: 10px;
    }

    .table-box {
      position: absolute;
      top: 365px !important;
      left: 10%;
      width: 80%;
      text-align: center;
      background-color: transparent !important;

      tbody,
      tr,
      th,
      td {
        background-color: transparent !important;
      }

      th,
      td {
        padding: 8px !important;
        font-size: 16px !important;
        color: black;
        span {
          display: block;
          font-size: 12px !important;
          margin-bottom: 6px;
        }
        vertical-align: top;
        border-top: 0.0625rem solid #7a7b7c;
        text-align: left;
      }
    }

    .cert-box {
      position: absolute;
      top: 1380px !important;
      left: 130px !important;
      text-align: left;
    }

    .signature-image-box {
      position: absolute;
      top: 1310px !important;
      left: 860px !important;
      text-align: center;

      >img {
        max-height: 80px !important;
        width: auto;
      }
    }
  }

  .print-cert-box {
    // width: 1200px !important;
    // padding-top: 40px;
    position: relative;
    width: 100% !important;
    margin: auto;
    &::before {
      content: ' ';
      display: block;
      width: 100%;
      // height: 10px;
    }

    .vessel-name-box {
      top: 41% !important;

      >div {
        >span {
          font-size: 28px !important;
          color: black;
        }
      }
    }

    .title-box {
      top: 49% !important;

      >div {
        >span {
          font-size: 28px !important;
          color: black;
        }
      }
    }

    .number-box {
      top: 58% !important;

      >div {
        >span {
          font-size: 32px !important;
          color: black;
        }
      }
    }

    .date-box {
      top: 80% !important;
      left: 15% !important;

      >div {
        >span {
          font-size: 28px !important;
          color: black;
        }
      }
    }

    .cert-number-box {
      top: 92% !important;
      left: 12% !important;

      >div {
        >span {
          font-size: 28px !important;
          color: black;
        }
      }
    }

    .signature-image-box {
      position: absolute;
      top: 78% !important;
      left: 58% !important;
      text-align: center;

      >img {
        max-height: 150px !important;
        width: auto;
      }
    }
  }
}

.print-permit-box {
  position: relative;
  width: 640px;
  margin: auto;

  .vessel-name-box {
    position: absolute;
    top: 230px;
    left: 0;
    width: 100%;
    text-align: center;

    >div {
      max-width: 70%;
      margin: auto;
      text-align: center;

      >span {
        font-size: 16px;
        line-height: 1.3;
        font-weight: bolder;
        text-transform: uppercase;
      }
    }
  }

  .title-box {
    position: absolute;
    top: 290px;
    left: 0;
    width: 100%;
    text-align: center;

    >div {
      max-width: 70%;
      margin: auto;
      text-align: center;

      >span {
        font-size: 16px;
        line-height: 1.3;
        font-weight: bolder;
        text-transform: uppercase;
      }
    }
  }

  .number-box {
    position: absolute;
    top: 370px;
    left: 0;
    width: 100%;
    text-align: center;

    >div {
      max-width: 70%;
      margin: auto;
      text-align: center;

      >span {
        font-size: 16px;
        line-height: 1.3;
        font-weight: bolder;
        text-transform: uppercase;
      }
    }
  }

  .date-box {
    position: absolute;
    top: 400px;
    left: 0;
    width: 100%;
    text-align: center;

    >div {
      max-width: 90%;
      margin: auto;
      text-align: center;

      >span {
        font-size: 28px;
        line-height: 1;
        font-weight: bolder;
        text-transform: uppercase;
      }
    }
  }

  .signature-image-box {
    position: absolute;
    top: 480px;
    left: 330px;
    text-align: center;

    >img {
      max-height: 50px;
      width: auto;
    }
  }
}

.print-inspection-box {
  position: relative;
  width: 640px;
  margin: auto;

  .table-box {
    position: absolute;
    top: 190px;
    left: 10%;
    width: 80%;
    text-align: center;

    th,
    td {
      padding: 0.21rem;
      font-size: x-small;
      color: black;
      span {
        display: block;
        font-size: xx-small;
        margin-bottom: 6px;
      }
      vertical-align: top;
      border-top: 0.0625rem solid #7a7b7c;
      text-align: left;
    }
  }

  .cert-box {
    position: absolute;
    top: 790px;
    left: 80px;
    text-align: left;
  }

  .signature-image-box {
    position: absolute;
    top: 760px;
    left: 510px;
    text-align: center;

    >img {
      max-height: 50px;
      width: auto;
    }
  }
}

.print-cert-box {
  position: relative;
  width: 640px;
  margin: auto;

  .vessel-name-box {
    position: absolute;
    top: 370px;
    left: 0;
    width: 100%;
    text-align: center;

    >div {
      max-width: 70%;
      margin: auto;
      text-align: center;

      >span {
        font-size: 16px;
        line-height: 1.3;
        font-weight: bolder;
        text-transform: uppercase;
      }
    }
  }

  .title-box {
    position: absolute;
    top: 430px;
    left: 0;
    width: 100%;
    text-align: center;

    >div {
      max-width: 70%;
      margin: auto;
      text-align: center;

      >span {
        font-size: 16px;
        line-height: 1.3;
        font-weight: bolder;
        text-transform: uppercase;
      }
    }
  }

  .number-box {
    position: absolute;
    top: 495px;
    left: 0;
    width: 100%;
    text-align: center;

    >div {
      max-width: 70%;
      margin: auto;
      text-align: center;

      >span {
        font-size: 24px;
        line-height: 1.3;
        font-weight: bolder;
        text-transform: uppercase;
      }
    }
  }

  .date-box {
    position: absolute;
    top: 725px;
    left: 90px;
    width: 100%;
    text-align: left;

    >div {
      max-width: 90%;
      margin: auto;
      text-align: left;

      >span {
        font-size: 16px;
        line-height: 1;
        font-weight: bolder;
        text-transform: uppercase;
      }
    }
  }

  .cert-number-box {
    position: absolute;
    top: 830px;
    left: 70px;
    width: 100%;
    text-align: left;

    >div {
      max-width: 90%;
      margin: auto;
      text-align: left;

      >span {
        font-size: 14px;
        line-height: 1;
        font-weight: bold;
      }
    }
  }
  
  .signature-image-box {
    position: absolute;
    top: 710px;
    left: 360px;
    text-align: center;

    >img {
      max-height: 80px;
      width: auto;
    }
  }
}